
import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import { mapState } from "vuex";
import { ISession } from "@/schemas/ISession";
import { IActivity } from "@/schemas/IActivity";
import IntroSlides from "@/components/slides/IntroSlides.vue";
import { ISlideElement } from "@/schemas/ISlideElement";
import { apiUrl } from "@/env";

@Component({
  name: "SessionPage",
  components: { IntroSlides },
  computed: {
    ...mapState("session", ["session"]),
  },
})
export default class SessionPage extends Vue {
  // The current session identifier
  @Prop({ required: true, type: String })
  sessionId!: string;

  // The current session object
  session: ISession | undefined;
  // Opened panel
  panel = [0];

  created(): void {
    this.$store.dispatch("session/fetchSession", {
      sessionId: this.sessionId,
      next: () => {
        // console.log(
        //   parseInt(clientCache.get(`${this.activity.id}:slides`)) !== 0,
        //   !this.displayVideo,
        //   this.$route.name == "session",
        //   !!this.activity,
        //   !!this.activity.slides.length
        // );

        if (
          !this.activity?.slides ||
          (this.activity?.slides?.length <= 0 &&
            !(this.$route.name === "groupSession") &&
            !(this.$route.name === "singleSession") &&
            !(this.$route.name === "transitionSession") &&
            !(this.$route.name === "tipsOverview"))) {
          this.$router.push({
            name: "singleSession",
            params: {
              sessionId: this.sessionId,
            },
          });
        }
        this.addBackgroundStyle();
      },
    });
  }

  get activityParagraphs(): string[] {
    let paragraphs: ISlideElement[] = [];
    this.activity?.slides?.forEach((slide) => {
      paragraphs.push(...slide.elements.sort((a, b) => a.order_number - b.order_number));
    });
    return paragraphs
      .filter((el) => el.type === "text")
      .map((el) => el.content);
  }

  addBackgroundStyle(): void {
    if (this.activity?.background_img) {
      let mainContainer = document.getElementById("main-container");
      mainContainer.style.backgroundImage = `url("${apiUrl}/background/${this.activity.id}/${this.activity?.background_img}")`;
      mainContainer.classList.add("bg");
    }
  }

  // handleSessionStatus(status: string) {
  //   console.log(status);
  //   if (status === sessionStateEnum.running_single) {
  //     // redirect to individual session
  //     this.$router.push({
  //       name: "singleSession",
  //       params: {
  //         sessionId: this.sessionId,
  //       },
  //     });
  //   }
  //   if (status === sessionStateEnum.running_group) {
  //     // redirect to group session
  //     this.$router.push({
  //       name: "groupSession",
  //       params: {
  //         sessionId: this.sessionId,
  //       },
  //     });
  //   }
  //   if (status === sessionStateEnum.finished) {
  //     // Call end of session
  //     // display end of session message
  //   }
  // }

  get activity(): IActivity | undefined {
    return this.session?.activity;
  }

  get displayGuideline(): boolean {
    return (
      (this.$route.name == "groupSession" ||
        this.$route.name == "singleSession") &&
      (!!this.activityParagraphs.length || !!this.activity?.guideline)
    );
  }
}
